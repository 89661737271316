// @ts-ignore
import React from "react"
import "twin.macro"
import { SEO } from "../components"
import { FrontPageTemplate } from "../gatsby-templates"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <FrontPageTemplate />
  </>
)

export default IndexPage
